import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateFetchingJobListStatus,
  updateJobListFilter,
  updateSearchFeedback,
  updateSelectedJob,
} from "../../../redux/actions/job_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import AccordionJobCard from "../AccordionJobCard/AccordionJobCard";
import AiJobSummary from "../AiJobSummary/AiJobSummary";
import JobAlertToggle from "../JobAlertToggle/JobAlertToggle";
import JobListNoResult from "../JobListNoResult/JobListNoResult";
import JobMobileAppAdCard from "../JobMobileAppAdCard/JobMobileAppAdCard";
import JobSearchFeedbackCard from "../JobSearchFeedbackCard/JobSearchFeedbackCard";
import JobWorkPersonaCard from "../JobWorkPersonaCard/JobWorkPersonaCard";
import {
  AccordionJobListSkeleton,
  AccordionMobileJobListSkeleton,
} from "../Skeleton/Skeleton";
import {
  ChevronLeftIconStyled,
  ChevronRightIconStyled,
  EmptyContainer,
  IconButtonStyled,
  JobListContainer,
  PageNumber,
  PaginationNumberBox,
  Wrapper,
} from "./styles";

function AccordionJobList(props) {
  const {
    isTabLoading,
    filterParams,
    loadingJobs,
    onFollowJobClicked,
    onApplyJobClicked,
    unSaveJob,
    currentId,
  } = props;

  const dispatch = useDispatch();

  const isDesktop = useMediaQuery("(min-width: 1280px)");
  const isMobile = useMediaQuery("(max-width: 640px)");

  const loggedIn = checkAuthentication();

  const user = useSelector((state) => state.user.user);
  const jobs = useSelector((state) => state.jobs.jobs);
  const isLoadingJobs = useSelector((state) => state.jobs.isLoadingJobs);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);

  const workPersonaStatus = user?.finishedWorkPersona;
  const hasMobileApp = user?.hasMobile;

  const showSearchFeedback = useSelector(
    (state) => state.jobs.searchFeedback.showCard
  );

  const keywordExist =
    useSelector((state) => state?.jobs?.jobListFilter["keyword"])?.length > 0;

  const scrollToComponent = (index) => {
    const componentRef = document.getElementById(index);
    var headerOffset = 155;
    var elementPosition = componentRef.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  function applyFilterParams(params) {
    // const cParams = { ...jobListFilter, ...params };
    const cParams = params;
    dispatch(updateJobListFilter(cParams)).then((res) => {
      if (isLoadingJobs === undefined) {
        return;
      }
      loadingJobs(true);
    });
  }

  useEffect(() => {
    if (filterParams !== undefined && !isTabLoading) {
      if (Object.keys(filterParams).length === 0) {
        return loadingJobs(true);
      }
      return applyFilterParams(filterParams);
    }
  }, [filterParams]);

  return (
    <Wrapper>
      {fetchingJobs || isLoadingJobs || jobs?.length > 0 ? (
        <Fragment>
          <JobListContainer>
            {fetchingJobs || isLoadingJobs ? (
              Array.from({ length: 10 }).map((_, index) => {
                return isMobile ? (
                  <AccordionMobileJobListSkeleton key={index} />
                ) : (
                  <AccordionJobListSkeleton key={index} />
                );
              })
            ) : (
              <Fragment>
                <JobAlertToggle jobAlertBannerId={`job-alert-banner-1`} />

                {jobs.map((job, index) => {
                  return (
                    <Fragment key={index}>
                      {/* show mobile app ad if user has not install the app */}
                      {/* only show the persona quiz if user has not taken the quiz */}
                      {index === 4 &&
                        (loggedIn && !hasMobileApp ? (
                          <JobMobileAppAdCard />
                        ) : !workPersonaStatus ? (
                          <JobWorkPersonaCard />
                        ) : null)}

                      {index === 8 ? (
                        <JobAlertToggle
                          jobAlertBannerId={`job-alert-banner-${index}`}
                        />
                      ) : index === 16 ? (
                        <JobAlertToggle
                          jobAlertBannerId={`job-alert-banner-${index}`}
                        />
                      ) : null}

                      <AccordionJobCard
                        job={job}
                        index={index}
                        onFollowJobClicked={onFollowJobClicked}
                        onApplyJobClicked={onApplyJobClicked}
                        unSaveJob={unSaveJob}
                        currentId={currentId}
                        origin={"job_list"}
                      />
                    </Fragment>
                  );
                })}
                {jobs?.length > 0 && keywordExist && showSearchFeedback && (
                  <JobSearchFeedbackCard />
                )}

                {fetchingJobs || jobs?.length > 0 ? <Pagination /> : null}
              </Fragment>
            )}
          </JobListContainer>
          {isDesktop ? <AiJobSummary /> : null}
        </Fragment>
      ) : (
        <JobListNoResult />
      )}
    </Wrapper>
  );
}

function Pagination(props) {
  const dispatch = useDispatch();
  const router = useRouter();

  const jobPageInfo = useSelector((state) => state.jobs.jobPageInfoV1);
  const currentPage = useSelector((state) => state.jobs.jobCurrentPage);
  const submitSearchFeedback = useSelector(
    (state) => state.jobs.searchFeedback.feedbackSubmit
  );

  function parsePageUrl(pageDirection) {
    const query = router.query;
    const { filter, ...queryParams } = query;
    const { page, ...rest } = queryParams;

    const slug = filter.join("/");

    const newPage =
      pageDirection === "next" ? currentPage + 1 : currentPage - 1;
    const isFirstPage = newPage === 1 ? true : false;

    router.push(
      {
        pathname: slug,
        query: {
          ...rest,
          ...(!isFirstPage && { page: newPage }),
        },
      },
      undefined,
      { shallow: true }
    );
  }

  function jobListNextPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "next",
    });

    parsePageUrl("next");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    dispatch(updateSelectedJob({}));
    window.scrollTo(0, 0);
  }

  function jobListPreviousPage() {
    sendTrackingEvent({
      event: "CE_clicked-jobs-page",
      "page-number": currentPage,
      action: "previous",
    });

    parsePageUrl("previous");
    dispatch(updateFetchingJobListStatus(true));

    if (submitSearchFeedback) {
      dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
    }
    dispatch(updateSelectedJob({}));
    window.scrollTo(0, 0);
  }

  return (
    <EmptyContainer
      sx={{ width: "100%", marginTop: "25px" }}
      container
      direction="row"
      justifyContent="center"
    >
      {jobPageInfo?.hasPreviousPage ? (
        <Fragment>
          <IconButtonStyled
            $margin_right="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListPreviousPage()}
            disabled={!jobPageInfo?.hasPreviousPage}
          >
            <ChevronLeftIconStyled />
          </IconButtonStyled>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage - 1}</PageNumber>
          </PaginationNumberBox>
        </Fragment>
      ) : null}

      <PaginationNumberBox $margin="true">
        <PageNumber variant="subtitle2">{currentPage}</PageNumber>
      </PaginationNumberBox>

      {jobPageInfo?.hasNextPage && (
        <Fragment>
          <PaginationNumberBox $non_focus="true">
            <PageNumber variant="subtitle2">{currentPage + 1}</PageNumber>
          </PaginationNumberBox>
          <IconButtonStyled
            $margin_left="true"
            $pagination="true"
            disableRipple
            onClick={() => jobListNextPage()}
            disabled={!jobPageInfo?.hasNextPage}
          >
            <ChevronRightIconStyled />
          </IconButtonStyled>
        </Fragment>
      )}
    </EmptyContainer>
  );
}

export default AccordionJobList;
